<template>
  <div class="instagram-feed">
    <div v-if="error" class="error">{{ error }}</div>
    <div v-else class="photos-grid">
      <div v-for="media in mediaData" :key="media.id" class="photo">
        <a :href="media.permalink" target="_blank" rel="noopener noreferrer">
          <!-- Display the media based on its type -->
          <img
            v-if="media.media_type === 'IMAGE'"
            :src="media.media_url"
            :alt="media.caption"
          />
          <img
            v-if="media.media_type === 'CAROUSEL_ALBUM'"
            :src="media.thumbnail_url || media.media_url"
            :alt="media.caption"
          />
          <video
            v-if="media.media_type === 'VIDEO' || media.media_type === 'REELS'"
            controls
          >
            <source :src="media.media_url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <!-- Dark overlay with Instagram icon -->
          <div class="overlay">
            <div class="instagram-icon">
              <i class="mdi mdi-instagram"></i>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "InstagramFeed",
  setup() {
    const mediaData = ref([]);
    const error = ref(null);

    const fetchInstagramFeed = async () => {
      const accessToken =
        "IGQWRQVEttMU1keDltaFhQQ0JoaXlGOFZAyU0F5dFVRbTV6bjFmWENQQkt4UGN6cFdDS2dhZATVVUmlrRUhVWFhEODVsLUl0NzJGcXBQa3dxQ2R1aGVYaVJ5OE50aVNPd1RUa1B1U0xkMkZAMdTFyNGlveGFBQzBzRUUZD";
      const userId = "8018514724911910";
      const apiUrl = `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url,permalink,thumbnail_url,caption&access_token=${accessToken}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (response.ok) {
          mediaData.value = data.data;
          if (mediaData.value?.length > 9) {
            mediaData.value = mediaData.value.slice(0, 9);
          }
        } else {
          throw new Error(
            data.error.message || "Failed to fetch Instagram data"
          );
        }
      } catch (err) {
        error.value = err.message;
        console.error(err);
      }
    };

    onMounted(fetchInstagramFeed);

    return {
      mediaData,
      error,
    };
  },
};
</script>

<style lang="scss" scoped>
/* General grid and media styling */
.instagram-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  padding-left: rem-calc(8) !important;
  padding-right: rem-calc(8) !important;
  @media (min-width: 961px) {
    padding-left: rem-calc(72) !important;
    padding-right: rem-calc(72) !important;
  }
}

.photo {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}

.photo img,
.photo video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  opacity: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  transition: opacity 0.3s ease-in-out;
}

.photo:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}

/* Instagram icon styling */
.instagram-icon {
  color: white;
  font-size: 24px;
}
</style>
