<template>
  <v-container fluid>
    <v-row class="align-center pt-10 pb-10 pt-md-2 pb-md-2">
      <v-col cols="12" md="7" class="text-col">
        <div class="sliding-text-wrapper pt-4 pt-md-0">
          <div class="sliding-text font-weight-bold header-sub-font">
            <span v-for="(word, index) in message" :key="index">
              {{ word }}&nbsp;
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <v-img
          src="https://lh3.googleusercontent.com/d/15iHTseYiMOxzMtaO2fKxIDvVqslINZqt"
          height="300"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from "vue";
export default {
  name: "BlockBanner",
  setup() {
    const message = ref([
      "“We Didn’t Just Draw Plans,",
      " We Drew Global Attention!”",
    ]);

    return {
      message,
    };
  },
};
</script>

<style lang="scss" scoped>
.sliding-text-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Vertically center the text */
}

.sliding-text {
  display: inline-block;
  white-space: nowrap;
  animation: slide 10s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.text-col {
  @media (max-width: 960px) {
    order: 2;
  }
}
</style>
